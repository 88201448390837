import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Speed Bench Press 5×2\\@80% 1RM `}<em parentName="p">{`(control down, up fast)`}</em></p>
    <p>{`Wt’d Strict Pullups 5×2`}</p>
    <p>{`then, for time:`}</p>
    <p>{`50 Calorie Bike`}</p>
    <p>{`50 Ring Pushups`}</p>
    <p>{`50 Bodyweight Rows `}<em parentName="p">{`(lying under racked barbell at hip height)`}</em></p>
    <p>{`50 Burpees`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      